<template>
  <section class="block builder-visual">
    <div style="max-width: 1024px" class="absolute inset-0">
      <visual-layers :build="testing" />
    </div>
    <div class="absolute -bottom-12 left-0 lg:bottom-12 lg:left-32">
      <p>
        <span>Your configuration:</span>
        <span v-if="configurationTotal > 0" class="block text-3xl"
          >{{ configurationTotal }}&euro;</span
        >
        <span v-else class="block">Select a model!</span>
      </p>
    </div>
  </section>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { useCartControls } from '@/composable/cart';
import VisualLayers from './VisualLayers';

const props = defineProps({
  config: {
    type: Object,
  },
});

const { configurationTotal, getItemByCategory } = useCartControls();

const testing = computed(() => {
  let images;
  let imageMap = {};

  props.config.forEach(({ name }) => {
    imageMap[name] = getItemByCategory(name);
  });

  return imageMap;
});
</script>

<style lang="scss">
.builder-visual {
  position: relative;
  left: 0;
  right: 0;
  // width: 100%;
  height: 40vh;
  margin-bottom: 0 !important;

  & img {
    height: 100%;
    object-fit: contain;
    margin: auto;
  }
}

@media only screen and (min-width: 1024px) {
  $drawer-width: 440px;
  .builder-visual {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: $drawer-width;
    width: auto;
    height: 100%;
    margin-bottom: 0 !important;

    & img {
      height: auto;
      margin: auto;
    }
  }
}
</style>
