<template>
  <button
    v-if="!option.disabled"
    @click="active ? null : $emit('handleCartAction', option)"
    :class="active ? 'border-2' : 'border'"
    class="relative block px-2 py-4 w-full border-black text-sm overflow-hidden"
  >
    <i v-if="active" class="absolute top-0 right-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="currentColor"
        class="bi bi-check"
        viewBox="0 0 16 16"
      >
        <path
          d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
        />
      </svg>
    </i>
    <div class="relative overflow-hidden">
      <template v-if="option.img">
        <div class="w-1/3 float-left">
          <figure
            :style="{ backgroundImage: 'url(' + option.img + ')' }"
            class="card-avatar rounded-full bg-white"
          ></figure>
        </div>
      </template>
      <div class="w-2/3 pl-2 float-left">
        <ul class="text-left">
          <li>{{ option.name }}</li>
          <li><span v-if="addPrice">+</span>{{ option.price || '0' }}&euro;</li>
        </ul>
        <div
          :class="{ 'opacity-20': !active }"
          class="text-left transition duration-150"
          v-if="addOns.length > 0"
        >
          <ul class="pt-2">
            <li v-for="addOn in addOns" :key="addOn.name">
              <label for="">
                <input type="checkbox" />
                <span class="ml-2">{{ addOn.name }}</span>
                <span class="ml-2">+{{ addOn.price || '0' }}&euro;</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <button
      @click.stop="$emit('handleCartAction', option, true)"
      v-if="active && !option.isDefault"
      class="absolute bottom-0 right-0 p-1 transition duration-150"
    >
      <span class="border border-black px-1">Remove</span>
    </button>
  </button>
</template>

<script setup>
import { computed, defineProps, defineEmit } from 'vue';

defineEmit(['handleCartAction']);

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  active: {
    type: Boolean,
  },
  addPrice: {
    type: Boolean,
  },
});

const addOns = (props.option.meta_data || [])
  .filter((meta) => meta.key === 'addOn')
  .flatMap((m) => m.value);
</script>

<style lang="scss">
.card-avatar {
  // background-image: url('../../assets/img/mk2-model.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 66%;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}
</style>
