<template>
  <figure
    class="relative flex items-center justify-center h-full overflow-hidden"
  >
    <img
      class="absolute inset-0"
      src="@/assets/img/configurator/8bit/00_base_1210mk2_VG+.png"
      alt=""
    />
    <template v-if="isSilver">
      <img
        class="absolute inset-0"
        src="@/assets/img/configurator/8bit/01_body_1200mk2_VG+.png"
        alt=""
      />
      <img
        v-if="isRough"
        class="absolute inset-0"
        src="@/assets/img/configurator/8bit/01_body_1200mk5_nasty.png"
        alt=""
      />
      <img
        class="absolute inset-0"
        src="@/assets/img/configurator/8bit/02_tone_1200.png"
        alt=""
      />
    </template>
    <template v-if="isBlack && isRough">
      <img
        v-if="isRough"
        class="absolute inset-0"
        src="@/assets/img/configurator/8bit/01_body_1210_rough.png"
        alt=""
      />
      <img
        class="absolute inset-0"
        src="@/assets/img/configurator/8bit/02_tone_1210.png"
        alt=""
      />
    </template>
    <template v-if="hasStylus">
      <img
        v-if="isConcorde"
        class="absolute inset-0"
        src="@/assets/img/configurator/8bit/04_stylii_conc_silver.png"
        alt=""
      />
      <img
        v-if="isM44"
        class="absolute inset-0"
        src="@/assets/img/configurator/8bit/04_stylii_M447.png"
        alt=""
      />
    </template>
    <template v-if="hasSlipmat">
      <img
        v-if="isRubbermat"
        class="absolute inset-0"
        src="@/assets/img/configurator/8bit/03_slip_rubber.png"
        alt=""
      />
      <img
        v-if="isTechnicsSlipmat"
        class="absolute inset-0"
        src="@/assets/img/configurator/8bit/03_slip_felt_technics1.png"
        alt=""
      />
    </template>
    <template v-if="hasFeet">
      <img
        v-if="isIsonoeBlack"
        class="absolute inset-0"
        src="@/assets/img/configurator/8bit/05_feet_Isonoe_blk.png"
        alt=""
      />
      <img
        v-if="isIsonoeSilver"
        class="absolute inset-0"
        src="@/assets/img/configurator/8bit/05_feet_Isonoe_silver.png"
        alt=""
      />
    </template>
  </figure>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useCartControls } from '@/composable/cart';

const { getItemByCategory } = useCartControls();

const isSilver = computed(() => {
  const { name } = getItemByCategory('Color')[0] || '';

  return name && name === 'Silver';
});

const isBlack = computed(() => {
  const { name } = getItemByCategory('Color')[0] || '';

  return name && name === 'Black';
});

const isRough = computed(() => {
  const { name } = getItemByCategory('Appearance')[0] || '';

  return name && name === 'Fair';
});

const hasStylus = computed(() => getItemByCategory('Cartridge').length);

const isConcorde = computed(() => {
  const cartridges = getItemByCategory('Cartridge');

  return cartridges.some(({ name }) => name === 'Ortofon Concorde MK1 Pro');
});

const isM44 = computed(() => {
  const cartridges = getItemByCategory('Cartridge');

  return cartridges.some(
    ({ name }) => name === 'Ortofon OM refurbished, incl. new needle Pro S',
  );
});

const hasSlipmat = computed(() => getItemByCategory('Slipmat').length);

const isTechnicsSlipmat = computed(() => {
  const slipmats = getItemByCategory('Slipmat');

  return slipmats.some(({ name }) => name === 'Technics');
});

const isRubbermat = computed(() => {
  const slipmats = getItemByCategory('Slipmat');

  return slipmats.some(({ name }) => name === 'Technics Rubber Mat 3mm');
});

const hasFeet = computed(() => getItemByCategory('Feet').length);

const isIsonoeBlack = computed(() => {
  const feet = getItemByCategory('Feet');

  return feet.some(({ name }) => name === 'Isonoe Isolation Feet black');
});

const isIsonoeSilver = computed(() => {
  const feet = getItemByCategory('Feet');

  return feet.some(({ name }) => name === 'Isonoe Isolation Feet white');
});

const props = defineProps({
  build: {
    type: Object,
  },
});
</script>
