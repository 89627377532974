<template>
  <div
    class="relative h-full border border-black border-opacity-25 rounded-sm flex flex-col pb-2"
  >
    <panel-nav
      v-model:index="activeIndex"
      v-model:tab="currentTab.name"
      @toggleNavMenu="showAllOptions = !showAllOptions"
    ></panel-nav>
    <div class="px-4 pt-8 pb-2 flex flex-col flex-1 overflow-auto">
      <div class="mb-8">
        <panel-options :tab="currentTab.name" :options="options">
          <template v-slot:options="{ option }">
            <panel-options-card
              @handleCartAction="handleCartAction"
              :option="option"
              :active="option.active"
              :addPrice="currentTab.name !== 'Model'"
            />
          </template>
        </panel-options>
      </div>
      <div class="text-xs flex justify-between mb-8">
        <button :disabled="activeIndex === 0" @click="activeIndex -= 1">
          <span v-if="activeIndex !== 0" class="border-b border-black"
            >Prev: {{ config[activeIndex - 1].name }}</span
          >
        </button>
        <button :disabled="activeIndex === 13" @click="activeIndex += 1">
          <span v-if="activeIndex !== 13" class="border-b border-black"
            >Next: {{ config[activeIndex + 1].name }}</span
          >
        </button>
      </div>
      <div class="mb-16 flex-1">
        <h3>{{ currentTab.name }} Info</h3>
        <p class="border-t border-b border-black border-opacity-25 py-2">
          <span v-if="currentTab.infoText">{{ currentTab.infoText }}</span>
          <span v-else
            >Placeholder infotext. This is for general info on the configuration
            such as defaults etc</span
          >
        </p>
      </div>
      <div class="flex justify-between">
        <div class="self-end">
          <span class="opacity-50">Price</span>
          <span class="text-4xl">{{ configurationTotal }}&euro;</span>
        </div>
        <button class="border border-black bg-white p-4">
          <span>ADD TO CART</span>
        </button>
      </div>
    </div>
    <div
      v-if="showAllOptions"
      class="absolute inset-0 bg-white overflow-auto rounded-sm"
    >
      <panel-overview
        @toggleNavMenu="showAllOptions = !showAllOptions"
        @jumpToStep="
          ($event) => (activeIndex = config.findIndex((i) => i.name === $event))
        "
        :config="config"
      >
        <template v-slot="{ step }">
          <div class="pl-2 flex-1">
            <span class="block text-left">{{ step.name }}</span>
            <span class="block text-left text-gray-600 text-xs">{{
              getStepSelection(step)
            }}</span>
          </div>
        </template>
      </panel-overview>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, reactive, inject } from 'vue';

import PanelNav from './PanelNav.vue';
import PanelOptions from './PanelOptions.vue';
import PanelOverview from './PanelOverview.vue';
import PanelOptionsCard from './PanelOptionsCard.vue';

import { useCartControls } from '@/composable/cart';
import { options as data, config } from '@/static/configuratorData';

// Inject cart API

const {
  addToCart,
  removeFromCart,
  isInCart,
  getItemByCategory,
  activeModel,
  configurationTotal,
} = useCartControls();

const showAllOptions = ref(false);
const activeIndex = ref(0);
const currentTab = computed(() => config[activeIndex.value]);

const handleCartAction = (product, remove = false) => {
  product._category = currentTab.value.name;

  remove
    ? removeFromCart(product)
    : addToCart(product, !currentTab.value.multiple);
};

const getStepSelection = (step) => {
  const items = getItemByCategory(step.name);

  return items.length
    ? items.length > 1
      ? items.length + ' ' + 'items selected.'
      : items[0].name
    : step.required
    ? 'Please select one'
    : step.default;
};

// Loop through all available options and
// 1. Filter out options according to current tabindex
// 2. Add metadata, eg. isActive, isConfigurator etc.
// 3. [...]

// Doing this, we won't have to fiddle around with the cart too much, but only loop through the options once
// every time the tabindex changes. Plus all the data relevant for the configurator stays local

let currentOptions = computed(() =>
  data.filter((option) =>
    option.categories.some(({ name }) => name === currentTab.value.name),
  ),
);

const options = computed(() => {
  let anyActive;

  let options = currentOptions.value.map((option) => {
    if (!option.isConfigurator) {
      option.isConfigurator = true;
    }

    let isActive = isInCart(option.id);

    if (isActive) {
      option.active = true;
      anyActive = true;
    } else {
      option.active = false;
    }

    return option;
  });

  if (currentTab.value.name === 'Pitch Fader') {
    let model = activeModel();

    let shouldDisable = model && model.name !== 'MK2/MK3';

    options.forEach(
      (option) =>
        (option.disabled = shouldDisable === undefined ? true : shouldDisable),
    );
  }

  if ('default' in currentTab.value) {
    options.unshift({
      id: 0,
      name: currentTab.value.default,
      price: '0',
      img: '',
      active: !anyActive,
      isDefault: true,
      categories: [],
      meta_data: [],
    });
  }

  return options;
});
</script>
